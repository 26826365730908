*{
    scroll-behavior: smooth;
}

.rocket{
    animation: float 6s ease-in-out infinite;
  }
  
  @keyframes float {
      0% {
  
          transform: translatey(0px);
      }
      50% {
  
          transform: translatey(-60px);
      }
      100% {
  
          transform: translatey(0px);
      }
  }

  .rotate-center {
	-webkit-animation: rotate-center 10s linear infinite both;
	        animation: rotate-center 10s linear infinite both;
}

 @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .team{
    animation: team 6s ease-in-out infinite;
  }

  @keyframes team {
    0% {

        transform: translatey(0px);
    }
    50% {

        transform: translatey(-10px);
    }
    100% {

        transform: translatey(0px);
    }
}